import React from 'react';
import ShortArrayLeft from '../../images/short-arrow-left.svg';
import renderHtml from 'htmr';
import { decode } from 'html-entities';
import { getFormatNumberWithCommas } from '../../modules/timeline/components/EnterButton/utils';
import { useFetch } from '../../hooks';
import { Link } from 'gatsby';
import CurrencySwitcher from '../Header/currencyswitcher/CurrencySwitcher';

const ProjectDonationDetailCard = ({ title, id, goal, descriptions, featuredImage }) => {
  const [getLanguageProjectByIdRaisedMoney, raisedMoneyDataForLanguageById, error, loading] = useFetch();

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      if (id) {
        getLanguageProjectByIdRaisedMoney(`api/donate/total-donations-by-parent-project-id/${id}`, {
          onSuccess: () => {},
          onError: () => {},
        });
      }
    }
  }, [id]);

  const getPercentageOfRaisedMoney = (goalAmount) => {
    if (raisedMoneyDataForLanguageById?.data?.totalAmount) {
      const percentage = (raisedMoneyDataForLanguageById?.data?.totalAmount / goalAmount) * 100;
      return Math.ceil(percentage) + '%';
    }
    return 0;
  };

  return (
    <div className="give ">
      <div className="pd-x-lg ">
        <div className="pd-x-lg ">
          <Link to="/give" className="pd-x-lg back-button-container">
            <img src={ShortArrayLeft} />
            <p>Back</p>
          </Link>
        </div>
      </div>
      <div className="section title-section" style={{ paddingTop: '32px' }}>
        <div className="pd-x-lg">
          <div className="">
            <div className="d-flex" style={{ gap: 10 }}>
              <div>
                <p className="title">{title}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="donation-project-detail-card">
          <div className="pd-x-lg">
            <div className="">
              <div className="progress-track">
                <div className="progress-thumb" style={{ width: getPercentageOfRaisedMoney(+goal) }}></div>
              </div>
              <div className="goal-container">
                <p>
                  <b>
                    <CurrencySwitcher
                      price={
                        raisedMoneyDataForLanguageById?.data?.totalAmount
                          ? getFormatNumberWithCommas(raisedMoneyDataForLanguageById?.data?.totalAmount)
                          : 0
                      }
                      isCommaSeparated={true}
                    />
                  </b>{' '}
                  Raised
                </p>
                <p>
                  <b>
                    <CurrencySwitcher price={+goal} isCommaSeparated={true} />
                  </b>
                  Goal
                </p>
              </div>
              <p className="descriptions">{renderHtml(decode(descriptions?.html))}</p>
            </div>
            <div className="btn-group ">
              <Link to={`#donate-box`} className="btn btn-md btn-primary-ii btn-tour-jumbotron">
                Give Now
              </Link>
            </div>
          </div>
          <div>
            <img className="featured-image pd-x-lg" src={featuredImage} alt={title} />
          </div>
        </div>
      </div>
      {/* <div className="lower-descriptions-container  bg-gray">
        <div className="pd-x-lg">
          <p className="descriptions ">{renderHtml(decode(languageDonationCard?.lowerDescriptions?.html))}</p>
        </div>
      </div> */}
    </div>
  );
};

export default ProjectDonationDetailCard;
