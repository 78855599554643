import React, { useState } from 'react';
import { graphql } from 'gatsby';
import BodyClassName from 'react-body-classname';
import BackgroundImage from 'gatsby-background-image';

import Donation from '../../components/Donation';
import DefaultLayoutComponent from '../layouts/DefaultLayoutComponent';
import { getListDonationProjectForSelect } from '../../utils/functions';
import ProjectDonationDetailCard from '../../components/Donation/DonationProjectDetailCard';
import ReadPageContent from '../../components/Read/ReadPageContent';

const ProjectDetailDonationPage = (props) => {
  const {
    data: { bgGive, patreonThumb, languageDonationPage, donationsDetails, donationProjectDetailsPage },
    pageContext: { id, uid },
  } = props;

  const languageDonationDetailsData = languageDonationPage.edges[0].node.data;
  const donationDetailsData = donationsDetails.edges[0].node.data;
  const donationProjectDetailsData = donationProjectDetailsPage.nodes[0].data;

  const selectedDonationProject = donationDetailsData?.projects?.find((item) => item.detailsPage.id === id);

  const customProjectsLists = getListDonationProjectForSelect(
    donationDetailsData.projects,
    languageDonationDetailsData.body
  );

  //find if last dynamic body is black or gray so that we can have dynamic body color in donor background

  const ifLastSliceIsGrayOrBlackForBody =
    donationProjectDetailsData?.body.length !== 0
      ? donationProjectDetailsData?.body[donationProjectDetailsData?.body.length - 1].primary.backgroundColor ===
          'Grey' ||
        donationProjectDetailsData?.body[donationProjectDetailsData?.body.length - 1].primary.backgroundColor ===
          'Black'
      : false;

  return (
    <BodyClassName className="body-light  page-give">
      <DefaultLayoutComponent
        title={'Donate-' + selectedDonationProject.title.text}
        description={donationProjectDetailsData.descriptions}
      >
        <div className="site-main">
          <BackgroundImage
            Tag="section"
            className="page-banner over_lpd-banner bgImage bg-pos-center-top"
            fluid={donationDetailsData.backgroundImage.fluid}
            preserveStackingContext
          >
            <div className="overlay" />
          </BackgroundImage>
          <section className="give-container donation-project-detail-page  over_lpd-container bg-dark pd-0">
            <div className="wrapper wrapper-xl pd-x-md">
              <div className=" bg-white  pd-b-0">
                <ProjectDonationDetailCard
                  id={id}
                  title={selectedDonationProject.title.text}
                  goal={selectedDonationProject.goal}
                  descriptions={donationProjectDetailsData.descriptions}
                  featuredImage={selectedDonationProject.thumbnail.url}
                />
              </div>
            </div>

            <ReadPageContent body={donationProjectDetailsData?.body} />
            <div className="wrapper wrapper-xl pd-x-md">
              <div id="donate-box">
                <Donation
                  isCustomDonation={true}
                  patreonThumb={patreonThumb}
                  showTab={false}
                  projectsLists={customProjectsLists}
                  defaultSelected={customProjectsLists.find((item) => item.value === id)}
                  hideTaxDeductibleTab={true}
                  bgClassName={ifLastSliceIsGrayOrBlackForBody ? '' : 'bg-off-white'}
                />
              </div>
            </div>
          </section>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
};

export const query = graphql`
  query donationProjectDetailsPage($uid: String!) {
    bgGive: file(relativePath: { eq: "bg-give.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    patreonThumb: file(relativePath: { eq: "patreon-thumb.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1120) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    donationsDetails: allPrismicDonationsPage {
      edges {
        node {
          data {
            headingDescriptions: heading_descriptions {
              text
            }
            backgroundImage: background_image {
              alt
              url
              fluid(maxWidth: 1280) {
                ...GatsbyPrismicImageFluid
              }
            }
            projects {
              title {
                text
              }
              thumbnail {
                url
              }
              goal
              shortDescriptions: short_descriptions {
                text
              }
              featured
              detailsPage: details_page {
                id
                slug
                uid
                document {
                  __typename
                }
              }
            }
          }
        }
      }
    }
    languageDonationPage: allPrismicLanguageDonationsPage {
      edges {
        node {
          data {
            title {
              text
            }
            backgroundImage: background_image {
              alt
              url
              fluid(maxWidth: 1280) {
                ...GatsbyPrismicImageFluid
              }
            }
            titleImage: title_image {
              alt
              url
              fluid(maxWidth: 1280) {
                ...GatsbyPrismicImageFluid
              }
            }
            body {
              __typename
              ... on PrismicLanguageDonationsPageBodyLanguageProjects {
                slice_type
                slice_label
                id
                primary {
                  languageName: language_name {
                    text
                  }
                  shortDescriptions: short_descriptions {
                    text
                  }
                  flagCode: flag_code {
                    text
                  }
                  upperDescriptions: upper_descriptions {
                    text
                    html
                  }
                  lowerDescriptions: lower_descriptions {
                    text
                    html
                  }
                  featureImage: featured_image {
                    alt
                    url
                    fluid(maxWidth: 600) {
                      ...GatsbyPrismicImageFluid
                    }
                  }
                  goal
                }
              }
            }
          }
        }
      }
    }
    donationProjectDetailsPage: allPrismicDonationProjectDetails(filter: { uid: { eq: $uid } }) {
      nodes {
        data {
          title {
            text
          }
          descriptions {
            html
          }
          body {
            ... on PrismicDonationProjectDetailsBodyText {
              id
              primary {
                backgroundColor: background_color
                sectionContents: section_contents {
                  text: html
                }
              }
              sliceType: slice_type
            }
            ... on PrismicDonationProjectDetailsBodyPhoto {
              id
              items {
                image {
                  alt
                  fluid(maxWidth: 1200) {
                    ...GatsbyPrismicImageFluid
                  }
                }
              }
              sliceType: slice_type
              primary {
                backgroundColor: background_color
              }
            }
            ... on PrismicDonationProjectDetailsBodyVideo {
              id
              primary {
                backgroundColor: background_color
                videoId: video_id {
                  text
                }
              }
              sliceType: slice_type
            }
            ... on PrismicDonationProjectDetailsBodyQuote {
              id
              primary {
                backgroundColor: background_color
                sectionContents: section_contents {
                  text
                }
              }
              sliceType: slice_type
            }
          }
        }
        id
      }
    }
  }
`;

export default ProjectDetailDonationPage;
